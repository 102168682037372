// State
export const state = () => ({
	loading: false,
	languages: [],
	allLanguages: [],
	announcements: [],
	announcement: {},
	platforms: ['desktop', 'phone'],
	menubar: [],
	submenu: [],
	portfolios: [],
	cancelToken: {}
})

// Actions
export const actions = {
	// ? Languages
	// Get
	async languages({ commit, state }, params) {
		commit('loading', true)
		commit('setCancelToken', { languages: this.$axios.CancelToken.source() })

		let isActive = ''
		if (params?.is_active === false) {
			isActive = ``
		} else {
			isActive = '?is_active_bo=true'
		}
		await this.$axios
			.get(`/languages${isActive}`, {
				cancelToken: state.cancelToken.languages.token
			})
			.then((response) => {
				if (isActive === ``) {
					commit('setAllLanguages', response.data)
				} else {
					commit('setLanguages', response.data)
				}
				commit('loading', false)
			})
			.catch(() => false)
		commit('loading', false)
	},
	// ? Submenu
	// Get all
	submenu({ commit }, id) {
		const $this = this
		$this.$axios
			.get(`/menubar-providers/${id}`)
			.then((response) => {
				commit('setSubmenu', response.data)
			})
			.catch(() => false)
	},
	// Create
	async createSubmenu({ rootGetters }, form) {
		const formData = new FormData()

		formData.append('image', form.image)
		formData.append('portfolio_uuid', form.portfolio_uuid)

		if (typeof form.game_id !== 'undefined') formData.append('game_id', form.game_id)
		if (typeof form.game_provider_uuid !== 'undefined') formData.append('game_provider_uuid', form.game_provider_uuid)
		if (typeof form.product_id !== 'undefined') formData.append('product_id', form.product_id)

		const response = await this.$axios
			.post(`/menubar-providers/${form.id}`, formData)
			.then(() => {
				return true;
			})
			.catch(() => {
				return false;
			})
		return response;
	},
	// Delete
	async deleteSubmenu({ dispatch, rootGetters }, form) {
		await this.$axios
			.delete(`/menubar-providers/${form.submenuId}`)
			.then(() => {
				dispatch('submenu', form.providerId)
			})
			.catch((e) => this.$toast.error(e))
	},

	// update submenu
	async updateSubmenu({ rootGetters }, object) {
		const formData = new FormData()
		if (object.image) formData.append('image', object.image)
		formData.append('portfolio_uuid', object.portfolio_uuid)

		if (typeof object.game_id !== 'undefined') formData.append('game_id', object.game_id)
		if (typeof object.product_id !== 'undefined') formData.append('product_id', Array.isArray(object.product_id) ? object.product_id[0] : object.product_id)
		if (typeof object.game_provider_uuid !== 'undefined') formData.append('game_provider_uuid', object.game_provider_uuid)

		const response = await this.$axios
			.put(`/menubar-providers/${object.id}`, formData)
			.then(() => {
				return true;
			})
			.catch(() => false)
		return response;
	},
	async updateSubMenubarSequence({ commit, rootGetters }, data) {
		commit('loading', true)
		await this.$axios
			.put(`/menubar-providers/update-sequence`, data)
			.then(() => {
			})
			.catch(() => false)
			.finally(() => commit('loading', false))
	},
	async portfolios({ commit }) {
		commit('loading', true)
		await this.$axios
			.get(`/sbo-portfolio`)
			.then((response) => {
				commit('portfolios', response.data)
			})
			.catch(() => false)
			.finally(() => commit('loading', false))
	},
}

// Mutations
export const mutations = {
	setLanguages: (state, v) => {
		state.languages = v
	},
	setAllLanguages: (state, v) => {
		state.allLanguages = v
	},
	updateLanguage: (state, v) => {
		// const found = state.languages.find((o) => o.id === v.id)
		// found.is_active_bo = v.status
	},
	menubar: (state, v) => {
		state.menubar = v
	},
	setSubmenu: (state, v) => {
		state.submenu = v
	},
	updateSubmenu: (state, v) => {
		state.submenu[v.index].game_id = v.game_name
	},
	loading: (state, type) => {
		state.loading = type
	},
	portfolios: (state, type) => {
		state.portfolios = type
	},
	setCancelToken: (state, data) => {
		const oldData = { ...state.cancelToken }
		state.cancelToken = {
			...oldData, ...data
		}
	}
}

// Getters
export const getters = {
	languages: (state) => {
		return state.languages
	},
	getPortfolioNameByUUID: state => uuid => {
		return state.portfolios.find(v => v.id === uuid).display_name
	}
}
